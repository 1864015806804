<template>
  <div class="relative flex items-center justify-center min-h-screen px-4 bg-gray-100 sm:px-0">
    <div class="absolute bottom-0 z-0 w-full">
      <img src="@/assets/img/cities-and-cars-shuttlers.png" class="w-full auth-img">
    </div>
    <div
      class="absolute top-0 flex items-center justify-center w-full py-4 text-white bg-gray-type-1"
      style="height: 72px"
    >
      <app-logo />
    </div>
    <div
      class="z-10 w-full max-w-lg px-6 py-12 pt-8 pb-12 mt-24 bg-white border rounded-xl sm:px-16"
      
    >
      <p class="font-bold text-center text-gray-type-2">
        Log in to your business account
      </p>
      <p class="mt-2 text-xs font-medium text-center text-gray-type-3">
        Don’t have a business account?
        <router-link class="text-green-type-1" to="/register"
          >Register</router-link
        >
      </p>
      <div
        class="flex items-start p-3 my-5 bg-red-200 rounded-md"
        v-if="errorMessage"
      >
        <div class="text-sm font-medium text-red-500">{{ errorMessage }}</div>
      </div>
      <form class="mt-12" @submit.prevent="login">
        <div>
          <label class="text-xs font-medium text-gray-type-3" for="email"
            >Email address</label
          >
          <div class="mt-2">
            <input
              v-model="form.email"
              type="email"
              id="email"
              class="w-full h-12 px-3 py-4 text-xs font-medium outline-none placeholder-gray-type-3 bg-gray-type-4 focus:outline-none"
              placeholder="sample@mail.com"
              required
            />
          </div>
        </div>
        <div class="mt-6">
          <label class="text-xs font-medium text-gray-type-3" for="password"
            >Password</label
          >
          <div class="relative mt-2">
            <input
              v-model="form.password"
              :type="showPassword ? 'text' : 'password'"
              id="password"
              class="w-full h-12 px-3 py-4 text-xs font-medium outline-none placeholder-gray-type-3 bg-gray-type-4 focus:outline-none"
              required
            />
            <div
              class="absolute inset-y-0 right-0 flex items-center pr-2 text-xs font-medium cursor-pointer text-green-type-1"
              @click="showPassword = !showPassword"
            >
              {{ showPassword ? "Hide" : "Show" }}
            </div>
          </div>
        </div>
        <router-link
          to="/forgot-password"
          class="mt-2 text-xs font-medium text-green-type-1"
          >Forgot Password?</router-link
        >
        <app-button
          type="submit"
          class="justify-center block w-full h-12 py-4 mt-12 font-bold text-center text-white rounded focus:outline-none bg-green-type-1"
          :loading="processing"
        >
          Log In
        </app-button>
      </form>
    </div>
  </div>
</template>

<script>
import AppLogo from "@/components/AppLogo";

export default {
  components: {
    AppLogo,
  },
  data() {
    return {
      showPassword: false,
      processing: false,
      errorMessage: "",
      form: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    login() {
      if (this.processing) {
        return;
      }

      this.processing = true;
      this.errorMessage = null;

      this.$store
        .dispatch("auth/login", { ...this.form, type: "corporate" })
        .then(async () => {
          this.form.email = "";
          this.form.password = "";

          const redirect = this.$route.query.redirect;

          if (redirect) {
            this.$router.push({
              path: redirect,
            });
          } else {
            this.$router.push({
              name: "dashboard.home",
              query: { ...this.$route.query },
            });
          }
        })
        .catch((err) => {
          if (err.response && err.response.data.message) {
            this.errorMessage = err.response.data.message;
          } else {
            this.errorMessage = "Oops! An error occured, please try again.";
          }
        })
        .finally(() => (this.processing = false));
    },
  },
};
</script>