<template>
  <div class="min-h-screen">
    <div
      class="absolute top-0 flex items-center justify-center w-full py-4 text-white bg-gray-type-1"
      style="height: 72px"
    >
      <router-link to="/">
        <img src="@/assets/img/logo.svg" alt="Shuttlers" />
      </router-link>
    </div>
    <div class="flex flex-col items-center justify-center min-h-screen">
      <template v-if="!linkSent">
        <form class="w-full max-w-xs" @submit.prevent="sendPasswordResetEmail">
          <h2 class="text-2xl font-bold text-center text-dark-type-3">
            Forgot your password?
          </h2>
          <div class="mt-1 text-xs font-medium text-center text-dark-type-4">
            Don’t worry, it happens to the best of us.
          </div>
          <div
            class="flex items-start w-full p-3 mx-auto my-5 bg-red-200 rounded-md"
            v-if="errorMessage"
          >
            <div class="text-sm font-medium text-red-500">
              {{ errorMessage }}
            </div>
          </div>
          <div class="mt-12">
            <div class="mt-2">
              <label class="text-xs font-medium text-gray-type-3" for="email"
                >Email address</label
              >
              <div class="mt-2">
                <input
                  v-model="$v.form.email.$model"
                  type="email"
                  id="email"
                  class="w-full h-12 px-3 py-4 text-xs font-medium outline-none placeholder-gray-type-3 bg-gray-type-4 focus:outline-none"
                  placeholder="sample@mail.com"
                />
                <span
                  class="text-xs font-medium text-red-400"
                  v-if="$v.form.email.required.$invalid"
                  >Please enter your email address</span
                >
              </div>
            </div>
          </div>
          <app-button
            type="submit"
            class="justify-center block w-full h-12 py-4 mt-12 font-bold text-center text-white rounded focus:outline-none bg-green-type-1"
            :loading="processing"
          >
            Recover Password
          </app-button>
        </form>
      </template>
      <template v-else>
        <div class="w-full max-w-md">
          <img
            src="@/assets/img/email-sent.svg"
            class="block mx-auto text-center"
            alt="Check your email"
          />
          <h2 class="mt-4 text-2xl font-bold text-center text-dark-type-3">
            Check your email
          </h2>
          <div class="mt-1 text-sm font-medium text-center text-dark-type-4">
            Check your email for password reset instructions
          </div>
          <router-link
            to="/"
            class="block w-56 h-12 py-4 mx-auto mt-12 font-bold text-center text-white rounded bg-green-type-1"
            >Back to home</router-link
          >
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import axios from "@/services/axios";
import { required } from "@vuelidate/validators";

export default {
  data() {
    return {
      linkSent: false,
      errorMessage: "",
      form: {
        email: "",
        type: "user",
      },
      processing: false,
    };
  },
  validations() {
    return {
      form: {
        email: {
          required,
        },
      },
    };
  },
  methods: {
    sendPasswordResetEmail() {
      this.$v.form.$touch();

      if (this.processing || this.$v.form.$errors.length) {
        return;
      }

      this.processing = true;
      this.errorMessage = null;

        axios.post('/password', {  ...this.form, type: 'corporate' })
          .then(async () => {
            this.linkSent = true;
          })
          .catch(err => {
            this.linkSent = false;

            if (err.response && err.response.data.message) {
              this.errorMessage = err.response.data.message;
            } else {
              this.errorMessage = "Oops! An error occured, please try again.";
            }
          })
          .finally(() => (this.processing = false));
    },
  },
};
</script>