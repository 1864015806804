<script>
import { h } from "vue";
import Spinner from "./AppSpinner";

export default {
  props: {
    tag: {
      type: String,
      default: "button",
    },
    type: {
      type: String,
      default: "button",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    computedClass() {
      return ["inline-flex", "items-center"];
    },
  },
  render() {
    return h(
      this.tag,
      {
        class: this.computedClass,
        ...this.$props,
        type: this.type,
        disabled: this.loading,
        "aria-disabled": this.loading,
        tabIndex: 0,
      },
      [
        this.loading &&
          h(Spinner, {
            class: ["mr-2"],
          }),
        this.$slots.default(),
      ]
    );
  },
};
</script>