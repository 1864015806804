import Login from '../views/auth/Login.vue';
import Register from '../views/auth/Register.vue';
import ForgotPassword from '../views/auth/ForgotPassword.vue';
import ResetPassword from '../views/auth/ResetPassword.vue';

export default [
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            guest: true
        },
    },
    {
        path: '/register',
        name: 'register',
        component: Register,
        meta: {
            guest: true
        },
        props: true
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: ForgotPassword,
        meta: {
            guest: true
        },
    },
    {
        path: '/password/:token',
        name: 'reset-password',
        component: ResetPassword,
        props: true,
        meta: {
            guest: true
        },
    },
];